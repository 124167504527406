@import '../../../../mixins';
@import '../../../../variables';

.submit_button {
  @include gradient-gold;

  position: relative;
  width: 6.5rem;
  height: 6.5rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  line-height: 2.5rem;
  border: 3px solid transparent;
  color: transparent;
  background-clip: text;

  &::before {
    @include gradient-gold;

    content: '';
    position: absolute;
    inset: 0;
    border-radius: 9999px;
    padding: 3px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
  }

  @media (min-width: 640px) {
    width: 7.5rem;
    height: 7.5rem;
  }
}

.input {
  border: 3px solid $color-white;
}
