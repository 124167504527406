@import '../../../variables';
@import '../../../mixins';

.logo {
  @include gradient-gold;

  color: transparent;
  background-clip: text;

  &::before {
    @include gradient-gold;

    content: '';
    position: absolute;
    inset: 0;
    border-radius: 9999px;
    padding: 2px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
  }
}
