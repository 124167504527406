@import '../../../variables';

.nav_item {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-3px);
    text-shadow: $color-primary 1px 0 10px;
  }
}
