@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

* {
  box-sizing: border-box;
}

html {
  background: url(../src/assets/images/luxury.webp) no-repeat center center fixed;
  background-size: cover;
  height: 100%;
}

body {
  font-family: $font-family-base;
  margin: 0;
  padding: 0;
  height: 100%;
}
