@import '../../../../variables';
@import '../../../../mixins';

.swiper {
  width: 100%;
  height: 100%;

  .swiper-slide {
    height: auto;
  }
}

.swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
  margin-top: 32px;

  .swiper-pagination-bullet {
    @include gradient-gold;

    height: 12px;
    width: 12px;
  }
}
