@import '../../../../variables';
@import '../../../../mixins';

.button_container {
  bottom: 0;
  left: -30px;
  @media (min-width: 640px) {
    right: 0;
    bottom: auto;
    left: auto;
  }
}

.contact_button {
  @include gradient-gold;

  position: relative;
  width: 8.5rem;
  height: 8.5rem;
  margin-left: 48px;
  margin-bottom: 120px;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  line-height: 2.5rem;
  z-index: 10;
  border: 3px solid transparent;
  color: transparent;
  background-clip: text;

  &::before {
    @include gradient-gold;

    content: '';
    position: absolute;
    inset: 0;
    border-radius: 9999px;
    padding: 3px;
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    mask-composite: exclude;
  }

  @media (min-width: 640px) {
    width: 9.5rem;
    height: 9.5rem;
    margin-bottom: 0px;
    margin-left: 36px;
  }
}
