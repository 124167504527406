@import '../../../mixins';

.circle {
  @include gradient-gold;

  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.wrapper .circle:nth-last-child(1) {
  animation: jumpingAnimation 1.2s 0.6s linear infinite;
}
.wrapper .circle:nth-last-child(2) {
  animation: jumpingAnimation 1.2s 0.3s linear infinite;
  margin: 0 10px 0 10px;
}
.wrapper .circle:nth-last-child(3) {
  animation: jumpingAnimation 1.2s 0s linear infinite;
}

@keyframes jumpingAnimation {
  0% {
    transform: translate(0, 0);
  }
  16% {
    transform: translate(0, -15px);
  }
  33% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
