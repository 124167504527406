@import '../../../../mixins';

.item_container {
  display: flex;
  align-items: center;
}

.line {
  @include gradient-gold;

  height: 3px;
  width: 100%;
  color: transparent;
}

.item_1,
.item_3 {
  flex-direction: row-reverse;
}
