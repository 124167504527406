@import '../../../../variables';
@import '../../../../mixins';

.card {
  max-width: 350px;
  border-bottom: 3px solid transparent;
  transition: all 0.2s;
  cursor: pointer;

  .circle {
    @include gradient-gold;
    height: 0;
    width: 0;
    transition: all 0.2s;
  }

  &:hover {
    background-color: $color-shadow;
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(
      to right,
      #462523 0,
      #cb9b51 22%,
      #f6e27a 45%,
      #f6f2c0 50%,
      #f6e27a 55%,
      #cb9b51 78%,
      #462523 100%
    );
    border-image-slice: 1;
    width: 100%;
    .circle {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
