@import '../../../variables';

/* Position and sizing of burger button */
.bm_burger_button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm_burger_bars {
  background: $color-white;
}

/* Position and sizing of clickable cross button */
.bm_cross_button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm_cross {
  background: $color-white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm_menu_wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm_menu {
  background: $color-primary;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm_morph_shape {
  fill: $color-primary;
}

/* Wrapper for item list */
.bm_item_list {
  padding: 0.8em;
}

/* Styling of overlay */
.bm_overlay {
  background: rgba(0, 0, 0, 0.3);
}

.sidebar_list {
  li {
    margin-bottom: 24px;
  }
}
