@import '../../../../variables';
@import '../../../../mixins';

.vertical-timeline {
  &::before {
    @include gradient-gold;

    width: 3px;
  }
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 3px black, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
}

.vertical-timeline-element-content {
  border-radius: 0;
  background-color: $color-shadow;
  color: $color-white;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  border-image-slice: 1;
  box-shadow: 0 3px 0 transparent;

  .vertical-timeline-element-title,
  .vertical-timeline-element-subtitle,
  .vertical-timeline-element-date,
  p {
    font-size: 1.125rem;
  }

  .vertical-timeline-element-date {
    color: $color-white;
    opacity: 1;
  }
}
