@import '../../../mixins';

.text {
  h1,
  h2 {
    @include gradient-gold;

    color: transparent;
    background-clip: text;
  }
}
