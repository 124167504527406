.image {
  width: 400px;
  height: auto;

  @media (min-width: 768px) {
    width: 500px;
  }
}

.main_presentation {
  max-width: 750px;
  width: 100%;
}

.links {
  left: 0;
}
